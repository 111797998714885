/* loader.module.css */

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;

}

.loader img {
    width: 100px;

    height: 100px;

}