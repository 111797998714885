.main_title {
    color: rgba(21, 4, 158, 1);

    font-family: Aeonik;
    font-size: 36px;
    font-weight: 700;
    line-height: 41.04px;
    text-align: left;
    margin: 0;
    padding-bottom: 10px;
    width: 247px;
    height: 41px;
    margin-top: 25px;
    margin-left: -50px;
    gap: 0px;
}



.fleet_view_cantaint .container {
    max-width: 1440px !important;
}

.fleet_view_cantaint {
    height: 770px;
}

.table {
    border-collapse: collapse;
}

.table th {
    margin: 10px;
    border: none;
}

.dispacthbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 12.4px;
    top: 256.3px;
    margin-left: 8px;
    gap: 0px;
    font-family: Aeonik;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(21, 4, 158, 1);
}

.dispacingthbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 12.4px;
    top: 256.3px;
    margin-left: 8px;
    gap: 0px;
    font-family: Aeonik;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(21, 4, 158, 1);
    background-color: beige;
}

.buttondispaching {
    width: 102.3px;
    height: 31px;
    top: 247px;
    margin-left: 8px;
    gap: 0px;
    border-radius: 500px;
    border: 1px 0px 0px 0px;
    background: whitesmoke;
}

.buttondisabled {
    width: 102.3px;
    height: 31px;
    top: 247px;
    margin-left: 8px;
    gap: 0px;
    border-radius: 500px;
    border: 1px 0px 0px 0px;
    background: rgba(139, 139, 139, 1);
}

.disablehbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 12.4px;
    top: 256.3px;
    margin-left: 8px;
    gap: 0px;
    font-family: Aeonik;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: white;
}

.button {

    width: 102.3px;
    height: 31px;
    top: 247px;
    left: 1247px;
    gap: 0px;
    border-radius: 500px;
    border: 1px 0px 0px 0px;
    background: rgba(94, 248, 132, 1);
}

.table td {
    border-bottom: 1px dotted;
    font-family: Aeonik;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.96px;
    text-align: left;
    color: rgba(21, 4, 158, 1);

}

.table th {
    color: rgba(21, 4, 158, 1);
    font-family: Aeonik;
    font-size: 18px;
    font-weight: 700;
    line-height: 20.52px;
    text-align: left;
    ;
}

.topbar {
    min-height: 92px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #17159b;
}

.topbar .container {
    max-width: 95% !important;
    margin-left: 30px;
}


.topbar .topbar_logo {
    width: 184px;
    height: 54px;
    margin-left: 22px;
}

.fleet_view .container {
    max-width: 1440px !important;
}