.main_title {
    color: rgba(21, 4, 158, 1);
    width: 383px;
    height: 41px;
    top: 128px;
    margin-left: -50px;
    gap: 0px;
    font-family: Aeonik;
    font-size: 36px;
    font-weight: 700;
    line-height: 41.04px;
    text-align: left;
    margin-top: 20px;
}

.dispachbtndisabled {
    background: rgba(139, 139, 139, 1);
    width: 102.3px;
    height: 31px;
    top: 247px;
    left: 1247px;
    gap: 0px;
    border-radius: 500px;
    border: 1px;
    /* background: rgba(94, 248, 132, 1); */
    border-color: rgba(139, 139, 139, 1);
}

.fleet_view_cantaint .container {
    max-width: 1440px !important;
}

.fleet_view_cantaint {
    height: 770px;
}

.table {
    border-collapse: collapse;
}

.table th {
    margin: 10px;
    border: none;
}

.buttondispaching {

    width: 102.3px;
    height: 31px;
    top: 247px;
    /* margin-left: 50px; */
    gap: 0px;
    border-radius: 500px;
    border: 1px;
    /* color: blue; */
    background-color: white;
    border: 1px solid rgba(21, 4, 158, 1)
}

.stopspan {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 13px;
    top: 256px;
    /* margin-left: 1px; */
    gap: 0px;
    font-family: Aeonik;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(21, 4, 158, 1);
}

.Stopdispach {
    width: 105.3px;
    height: 31px;
    top: 247px;
    left: 1247px;
    gap: 0px;
    border-radius: 500px;
    border: 1px 0px 0px 0px;
    background: white;
    border-color: rgba(21, 4, 158, 1);
}

.dispachbtn {
    width: 102.3px;
    height: 31px;
    top: 247px;
    left: 1247px;
    gap: 0px;
    border-radius: 500px;
    border: 1px solid;
    background: rgba(94, 248, 132, 1);
    border-color: rgba(21, 4, 158, 1);
}

.table td {
    border-bottom: 1px dotted;
    font-family: Aeonik;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.96px;
    text-align: left;
    color: rgba(21, 4, 158, 1);

}

.table th {
    color: rgba(21, 4, 158, 1);
    font-family: Aeonik;
    font-size: 18px;
    font-weight: 700;
    line-height: 20.52px;
    text-align: left;
    ;
}

.topbar {
    min-height: 92px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #17159b;
}

.topbar .container {
    max-width: 95% !important;
    margin-left: 30px;
}

.topbar .topbar_logo {
    width: 184px;
    height: 54px;
    margin-left: 22px;
}

.disablebtn {
    width: 102.3px;
    height: 31px;
    top: 247px;
    left: 1247px;
    gap: 0px;
    border-radius: 500px;
    border: 1px 0px 0px 0px;
    background: rgba(139, 139, 139, 1);
}