.topbar {
    min-height: 92px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #17159b;
}

.topbar .container {
    max-width: 95% !important;
    /* margin-left: 30px; */
}

.topbar .topbar_logo {
    width: 184px;
    height: 54px;
    margin-left: 50px
}

.dispatchResources {
    width: 337px;
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin-top: 20px;
}

.frameChild {
    width: 337px;
    position: relative;
    display: inline-block;
    max-width: 100%;
}

.frameChild {
    /* width: 472px; */
    /* height: 228px; */
    background-color: var(--primary-purple-magnolia);
    display: block;
}

.thermostat {
    position: relative;
    z-index: 1;
    font-family: Aeonik;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.36px;
    text-align: left;
}

.deviceDispatcher {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-12xs);
    /* border-bottom: 1px solid gray;
    width: 400px; */
}

.borderdiv {
    border-bottom: 1px solid #D0CDEC;
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.maxDispatch {
    position: relative;
    z-index: 1;
}

.dispatch {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-12xs);
}

.globalSetPoint {
    align-self: stretch;
    position: relative;
    z-index: 1;
}

.dispatchSettings,
.maxDispatchToggle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dispatchSettings {
    align-self: stretch;
    gap: var(--gap-34xl);
}

.maxDispatchToggle {
    flex: 1;
    padding: var(--padding-10xs) 0 0;
    box-sizing: border-box;
    /* min-width: 162px; */
    padding-right: 10px;
}

.frameItem {
    height: 34px;
    width: 112px;
    position: relative;
    border-radius: var(--br-481xl);
    background-color: var(--secondary-green-spring-green);
    border: 1px solid var(--primary-purple-duke-blue);
    box-sizing: border-box;
    display: none;
}

.dispatch1 {
    position: relative;
    line-height: 24px;
    z-index: 1;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.dispatch1:hover {
    cursor: pointer;
}

.rectangleGroup {
    align-self: stretch;
    border-radius: var(--br-481xl);
    background-color: var(--secondary-green-spring-green);
    border: 1px solid var(--primary-purple-duke-blue);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-8xs) 0;
    z-index: 1;
    width: 112px !important;
}



.frameInner {
    height: 100%;
    width: 107px;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--br-3xs);
    background-color: var(--secondary-green-celadon);
    border: 0.5px solid var(--primary-purple-medium-slate-blue);
    box-sizing: border-box;
    z-index: 1;
}

.degreesSymbol {
    width: 31px;
    height: 32px;
    position: relative;
    letter-spacing: 0.1px;
    /* line-height: 180px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 2;
}

b {
    user-select: none;
}

.degreesSymbolWrapper {
    width: 81px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

b {
    user-select: none;
}

.b,
.lineDiv {
    position: relative;
    z-index: 2;

}

.b {
    width: 71px;
    letter-spacing: 0.1px;
    line-height: 32px;
    display: flex;
    color: var(--primary-purple-duke-blue);
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: -61px;
}

.lineDiv {
    width: 0.5px;
    height: 22.5px;
    border-right: 0.5px solid var(--primary-purple-medium-slate-blue);
    box-sizing: border-box;
}

.lineWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 var(--padding-9xs);
    margin-left: -61px;
}

.devicesDispatchingLabel {
    align-self: stretch;
    height: 32px;
    position: relative;
    letter-spacing: 0.1px;
    /* line-height: 180px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 2;
}

.devicesDispatchingLabelWrapper {
    width: 61px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-11xl) 0 0;
    box-sizing: border-box;
    margin-left: -61px;
}

.capacityIndicator {
    width: 0.5px;
    height: 22.5px;
    position: relative;
    border-right: 0.5px solid var(--primary-purple-medium-slate-blue);
    box-sizing: border-box;
    z-index: 2;
}

.capacityIndicatorWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 var(--padding-9xs);
    margin-left: -61px;
}

.frameParent,
.rectangleContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.rectangleContainer {
    align-self: stretch;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-11xl) var(--padding-8xs) 0;
    position: relative;
    color: var(--color-gray);
}

.frameParent {
    width: 112px;
    flex-direction: column;
    gap: var(--gap-20xl);
    min-width: 112px;
    text-align: center;
    font-size: var(--font-size-base);
}

.globalStopChargingButton {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /* gap: var(--gap-46xl); */
}

.rectangleDiv,
.rectangleParent {
    background-color: var(--primary-purple-magnolia);
    max-width: 100%;
}

.rectangleParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    box-sizing: border-box;
    gap: var(--gap-11xl);
    /* min-width: 3px; */
    /* width: 472px; */
    min-height: 238px !important;
    top: 246px;
    left: 36px;
    gap: 0px;
}

.rectangleDiv {
    width: 472px;
    height: 228px;
    position: relative;
    display: none;
}

.battery {
    display: inline-block;
    min-width: 82px;
}

.battery,
.maxDispatch1 {
    position: relative;
    z-index: 1;
}

.maxDispatchWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-10xs) 0 0;
}

.frameChild1 {
    min-height: 238px;
    width: 102.3px;
    position: relative;
    border-radius: var(--br-481xl);
    background-color: var(--neutral-white);
    border: 1px solid var(--primary-purple-duke-blue);
    box-sizing: border-box;
    display: none;
}

.dispatching {
    flex: 1;
    position: relative;
    line-height: 12.4px;
    display: inline-block;
    min-width: 70px;
    z-index: 1;
}

.frameGroup,
.groupDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.groupDiv {
    width: 102.3px;
    border-radius: var(--br-481xl);
    background-color: var(--neutral-white);
    border: 1px solid var(--primary-purple-duke-blue);
    box-sizing: border-box;
    justify-content: flex-start;
    padding: var(--padding-3xs-3) var(--padding-base-1) var(--padding-3xs-3) var(--padding-base-2);
    z-index: 1;
    text-align: center;
    font-size: var(--font-size-xs);
    cursor: pointer;
}

.frameGroup {
    width: 416.3px;
    justify-content: space-between;
    gap: var(--gap-xl);
    max-width: 100%;
}

.degreesSymbol,
.devicesDispatchingLabel:hover {
    cursor: pointer;
}

.frameChild2,
.frameDiv {
    background-color: var(--primary-purple-magnolia);
    max-width: 100%;
}

.frameDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px;
    box-sizing: border-box;
    /* gap: var(--gap-11xl); */
    min-width: 100%;
    min-height: 238px !important;
}

.frameChild2 {
    width: 472px;
    height: 228px;
    position: relative;
    display: none;
}

.electricVehicle {
    /* width: 172px; */
    position: relative;
    display: inline-block;
    z-index: 1;
}

.globalStopCharging {
    align-self: stretch;
    position: relative;
    z-index: 1;
}

.loaderImage {
    width: 30px;
    height: 30px;

}

.globalStopChargingWrapper {
    width: 229px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-10xs) 0 0;
    box-sizing: border-box;
}

.frameChild3 {
    height: 34px;
    width: 112px;
    position: relative;
    border-radius: var(--br-481xl);
    background-color: var(--secondary-green-spring-green);
    border: 1px solid var(--primary-purple-duke-blue);
    box-sizing: border-box;
    display: none;
}

.dispatch2 {
    flex: 1;
    position: relative;
    line-height: 24px;
    z-index: 1;
}

.frameContainer,
.rectangleParent2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.rectangleParent2 {
    width: 112px;
    border-radius: var(--br-481xl);
    background-color: var(--secondary-green-spring-green);
    border: 1px solid var(--primary-purple-duke-blue);
    box-sizing: border-box;
    justify-content: flex-start;
    padding: var(--padding-8xs) 0;
    z-index: 1;
    text-align: center;
    font-size: var(--font-size-base);
    cursor: pointer;
}

.frameContainer {
    align-self: stretch;
    justify-content: space-between;
    gap: var(--gap-xl);
}

.rectangleParent1,
.thermostatControl {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.rectangleParent1 {
    flex: 1;
    background-color: var(--primary-purple-magnolia);
    flex-direction: column;
    padding: var(--padding-lg) var(--padding-4xl);
    box-sizing: border-box;
    /* gap: var(--gap-11xl); */
    min-width: 354px;
    min-height: 238px;
}

.thermostatControl {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-7xl);
    margin-top: 32px;
}

.devicesDispatching {
    width: 100%;
    display: flex;
    align-self: stretch;
    position: relative;
    justify-content: space-between;
}

.degreesAltered {
    flex: 1;
    position: relative;
}

.degreesAlteredWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-12xs);
    box-sizing: border-box;
}

.estimatedCapacity {
    position: relative;
}

.devicesDispatchingParent,
.estimatedCapacityWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.estimatedCapacityWrapper {
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.devicesDispatchingParent {
    width: 100%;
    flex-direction: column;
    gap: var(--gap-16xl);
    padding: 15px 15px 0 15px;
    background-color: #fff;
}

.dipacthdivma {
    height: 475px;
}

.div {
    text-align: right;
}

.div,
.div1,
.kwh {
    position: relative;
    display: inline-block;
}

.div1 {
    text-align: right;
}

.kwh {
    align-self: stretch;
    text-align: right;
}

.temperatureAndCapacityTitle {
    width: 98px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-20xl);
}

.devicesDispatching1 {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.capacityDischarging {
    flex: 1;
    position: relative;
}

.deviceCapacityLabel,
.devicesDispatchingGroup {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.deviceCapacityLabel {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
   
}

.thirddisp {
    height: 475px;
}

.termo {
    width: 32%;
}

.devicesDispatchingGroup {
    /* width: 420px; */
    flex-direction: column;
    /* padding: 0 var(--padding-17xl) 0 0; */
    box-sizing: border-box;
    gap: var(--gap-16xl);
    text-align: left;
    padding: 15px 15px 0 15px;
}

.div2,
.kwh1 {
    position: relative;
    display: inline-block;
}

.div2 {
    text-align: right;
}

.kwh1 {
    align-self: stretch;
    min-width: 95px;
}

.parent {
    width: 95px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-20xl);
}

.devicesDispatching2 {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.chargingLoadReduced {
    flex: 1;
    position: relative;
}

.chargingLoadReducedWrapper,
.devicesDispatchingContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.chargingLoadReducedWrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 var(--padding-12xs);
}

.devicesDispatchingContainer {
    width: 268px;
    flex-direction: column;
    padding: 0 var(--padding-4xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-20xl);
    text-align: left;
}

.div3,
.kwh2 {
    position: relative;
    display: inline-block;
    text-align: right;
}

.kwh2 {

    text-align: right;
}

.group {
    width: 92px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-20xl);
}

.temperatureAndCapacityTitleParent {
    justify-content: flex-start;
    gap: var(--gap-43xl);
    text-align: right;
}

.frameParent1,
.frameWrapper,
.temperatureAndCapacityTitleParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
}

.frameParent1 {
    flex: 1;
    justify-content: space-between;
    gap: var(--gap-xl);
}

.frameWrapper {
    align-self: stretch;
    justify-content: flex-start;
    padding: 0 var(--padding-9xl) 0 var(--padding-3xl);
    box-sizing: border-box;
}

.thermostatControlParent {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-16xl);
    font-size: var(--font-size-5xl);
}

.colorLightContainer,
.dispatchResourcesParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.thermostatControlParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    margin-top: -30px;
}

.dispatchResourcesParent {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-58xl);
}

.colorLightContainer {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-17xl);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--font-size-17xl);
    color: var(--primary-purple-duke-blue);
    font-family: var(--font-aeonik);
    padding-bottom: 70px;
}

.maxDispatchToggle p,
.maxDispatchWrapper p,
.frameContainer p,
.termo p {
    font-size: 24px;
    margin: 0;
}

h2.dispatchResources {
    border-bottom: 1px solid #D0CDEC;
    margin-bottom: 67px;
    color: #15049E;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 40px 0 0 0;
    padding-bottom: 10px;
    width: 100%;
}

@media screen and (max-width: 1425px) {

    .frameDiv,
    .rectangleParent1 {
        min-height: 238px;
    }

    .frameParent1,
    .thermostatControl {
        flex-wrap: wrap;
    }

    .maxDispatchToggle p,
    .maxDispatchWrapper p,
    .frameContainer p,
    .termo p {
        font-size: 22px;
    }
}

@media screen and (max-width: 1200px) {
    .temperatureAndCapacityTitleParent {
        flex-wrap: wrap;
        gap: 62px 31px;
    }

    .dispatchResources {
        font-size: var(--font-size-10xl);
    }

    .rectangleParent {
        /* gap: 15px 30px; */
        min-width: 100%;
    }

    .frameDiv {
        min-width: 100%;
    }

    .frameContainer {
        flex-wrap: wrap;
    }

    .rectangleParent1 {
        /* gap: 15px 30px; */
        min-width: 100%;
    }

    .temperatureAndCapacityTitleParent {
        gap: 62px 15px;
    }

    .thermostatControlParent {
        gap: 17px 35px;
    }

    .dispatchResourcesParent {
        gap: 38px 77px;
    }



    .devicesDispatchingGroup {
        width: 92%;
        flex-direction: column;
        /* padding: 0 var(--padding-17xl) 0 0; */
        box-sizing: border-box;
        gap: var(--gap-20xl);
        text-align: left;
        margin-top: 53px;
        margin-left: 20px;
    }

    .deviceCapacityLabel {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        width: 100%;

    }

    .devicesDispatchingParent {
        width: 100%;
        flex-direction: column;
        gap: var(--gap-20xl);

    }

    .b {

        margin-left: -55px;
    }

    .degreesSymbol {

        margin-left: 04px;
    }

    .frameGroup {
        width: 100%;
        justify-content: space-between;
        gap: var(--gap-xl);
        max-width: 100%;
        display: flex;
        justify-content: spa;
    }

    .rectangleGroup {
        align-self: stretch;
        border-radius: var(--br-481xl);
        background-color: var(--secondary-green-spring-green);
        border: 1px solid var(--primary-purple-duke-blue);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: var(--padding-8xs) 0;
        z-index: 1;
        width: 120px;
        margin-left: -10px;
    }

    .thirddisp {
        height: 475px;
        width: 100%;
    }

    .dipacthdivma {
        height: 310px;
        margin-top: 264px !important;
        width: 100%;
    }

    .termo {
        margin-top: 22px;
        /* margin-left: -21px; */
        /* width: 485px; */
    }

    .thermostatControlParent {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 100%;
        margin-top: -30px;
    }

    .thermostatControl {
        flex-wrap: wrap;
    }

    .maxDispatchToggle p,
    .maxDispatchWrapper p,
    .frameContainer p,
    .termo p {
        font-size: 18px;
    }
}

@media screen and (max-width: 991px) {
    .dispatchResources {
        font-size: var(--font-size-10xl);
    }

    .rectangleParent {
        gap: 15px 30px;
        min-width: 100%;
        min-height: auto !important;
    }

    .frameDiv {
        min-width: 100%;
    }

    .frameContainer {
        flex-wrap: wrap;
    }

    .frameDiv,
    .rectangleParent1 {
        min-height: auto !important;
    }

    .rectangleParent1 {
        gap: 15px 30px;
        min-width: 100%;
    }

    .temperatureAndCapacityTitleParent {
        gap: 62px 15px;
    }

    .thermostatControlParent {
        gap: 17px 35px;
    }

    .dispatchResourcesParent {
        gap: 38px 77px;
    }

    .termo {
        margin-top: 22px;
        /* margin-left: -21px; */
        width: 100%;
    }

    .devicesDispatching2 {
        width: 100%;
        position: relative;
        display: flex;
        padding: 12px;
    }

    .chargingLoadReducedWrapper {
        align-self: stretch;
        flex-direction: row;
        padding: 12px;
    }

    .devicesDispatchingGroup {
        width: 100%;
        flex-direction: column;
        /* padding: 0 var(--padding-17xl) 0 0; */
        box-sizing: border-box;
        gap: var(--gap-20xl);
        text-align: left;
        margin-top: 53px;
        margin-left: 0px;
    }

    .deviceCapacityLabel {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 12px;

    }

    .devicesDispatching1 {
        width: 100%;
        position: relative;
        padding: 12px;
        display: flex;
        justify-content: space-between;
    }

    .devicesDispatchingParent {
        width: 100%;
        flex-direction: column;
        gap: var(--gap-20xl);
        margin-top: 0px;
        margin-left: 0px;
    }

    .b {

        margin-left: -55px;
    }

    .degreesSymbol {

        margin-left: 04px;
    }

    .frameGroup {
        width: 100%;
        justify-content: space-between;
        gap: var(--gap-xl);
        max-width: 100%;
        display: flex;
        justify-content: spa;
    }

    .rectangleGroup {
        align-self: stretch;
        border-radius: var(--br-481xl);
        background-color: var(--secondary-green-spring-green);
        border: 1px solid var(--primary-purple-duke-blue);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: var(--padding-8xs) 0;
        z-index: 1;
        width: 120px;
        margin-left: -10px;
    }

    .thirddisp {
        height: 475px;
        width: 100%;
    }

    .dipacthdivma {
        height: 310px;
        margin-top: 264px !important;
        width: 100%;
    }

    .maxDispatchToggle p,
    .maxDispatchWrapper p,
    .frameContainer p,
    .termo p {
        font-size: 16px;
    }

}

@media screen and (max-width: 767px) {
    .dispatchResources {
        font-size: var(--font-size-10xl);
    }

    .rectangleParent {
        gap: 15px 30px;
        min-width: 100%;
    }

    .frameDiv {
        min-width: 100%;
    }

    .frameContainer {
        flex-wrap: wrap;
    }

    .rectangleParent1 {
        gap: 15px 30px;
        min-width: 100%;
    }

    .temperatureAndCapacityTitleParent {
        gap: 62px 15px;
    }

    .thermostatControlParent {
        gap: 17px 35px;
    }

    .dispatchResourcesParent {
        gap: 38px 77px;
    }

    .termo {
        margin-top: 22px;
        /* margin-left: -21px; */
        width: 100%;
    }

    .devicesDispatchingGroup {
        width: 97%;
        flex-direction: column;
        /* padding: 0 var(--padding-17xl) 0 0; */
        box-sizing: border-box;
        gap: var(--gap-20xl);
        text-align: left;
        margin-top: 53px;
        margin-left: 20px;
    }

    .deviceCapacityLabel {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        width: 100%;

    }

    .devicesDispatchingParent {
        width: 100%;
        flex-direction: column;
        gap: var(--gap-20xl);
        margin-top: 41px;
        margin-left: 0px;
    }

    .b {

        margin-left: -55px;
    }

    .degreesSymbol {

        margin-left: 04px;
    }

    .frameGroup {
        width: 100%;
        justify-content: space-between;
        gap: var(--gap-xl);
        max-width: 100%;
        display: flex;
        justify-content: spa;
    }

    .rectangleGroup {
        align-self: stretch;
        border-radius: var(--br-481xl);
        background-color: var(--secondary-green-spring-green);
        border: 1px solid var(--primary-purple-duke-blue);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: var(--padding-8xs) 0;
        z-index: 1;
        width: 120px;
        margin-left: -10px;
    }

    .thirddisp {
        height: 475px;
        width: 100%;
    }

    .dipacthdivma {
        height: 310px;
        margin-top: 264px !important;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .dispatchResources {
        font-size: var(--font-size-3xl);
    }

    .globalSetPoint,
    .maxDispatch,
    .thermostat {
        font-size: var(--font-size-lgi);
    }

    .dispatchSettings {
        gap: 26px 53px;
    }

    h2.dispatchResources {
        font-size: 26px;

    }

    .globalStopChargingButton {
        flex-wrap: wrap;
        gap: 65px 32px;
    }

    .battery,
    .maxDispatch1 {
        font-size: var(--font-size-lgi);
    }

    .frameGroup {
        flex-wrap: wrap;
    }

    .frameDiv {
        gap: 15px 30px;
    }

    .capacityDischarging,
    .degreesAltered,
    .devicesDispatching,
    .devicesDispatching1,
    .div,
    .div1,
    .electricVehicle,
    .estimatedCapacity,
    .globalStopCharging,
    .kwh {
        font-size: var(--font-size-lgi);
    }

    .devicesDispatchingGroup {
        gap: 19px 39px;
        margin-left: 0px;
    }

    .chargingLoadReduced,
    .devicesDispatching2,
    .div2,
    .kwh1 {
        font-size: var(--font-size-lgi);
    }

    .globalStopChargingWrapper {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: var(--padding-10xs) 0 0;
        box-sizing: border-box;
    }

    .colorLightContainer {
        align-self: stretch;
        flex-direction: row;
        padding: 15px;
        box-sizing: border-box;
        text-align: left;
        font-size: var(--font-size-17xl);
        color: var(--primary-purple-duke-blue);
        font-family: var(--font-aeonik);
    }

    .devicesDispatchingContainer {
        gap: 19px 39px;
    }

    .div3,
    .kwh2 {
        font-size: var(--font-size-lgi);
    }

    .dispatchResourcesParent {
        gap: 19px 77px;
    }

    .topbar_logo {
        width: 184px;
        height: 54px;
        /* margin-left: 20px !important; */
    }

    .termo {
        margin-top: 22px;
        /* margin-left: -21px; */
        width: 100%;
    }

    .devicesDispatchingParent {
        /* width: 420px; */
        flex-direction: column;
        gap: var(--gap-20xl);
        margin-top: 41px;
        margin-left: 0px;
    }

    .b {

        margin-left: -55px;
    }

    .globalSetPoint {
        align-self: stretch;
        position: relative;
        z-index: 1;
        padding-top: 30px;
    }

    .degreesSymbol {

        margin-left: 8px;
    }

    .rectangleGroup {
        align-self: stretch;
        border-radius: var(--br-481xl);
        background-color: var(--secondary-green-spring-green);
        border: 1px solid var(--primary-purple-duke-blue);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: var(--padding-8xs) 0;
        z-index: 1;
        width: 120px;
        margin-left: px;
    }

    .thirddisp {
        height: 475px;
        width: 100%;
    }

    .dipacthdivma {
        height: 310px;
        margin-top: 264px !important;
        width: 100%;
    }
}