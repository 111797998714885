@font-face {
    font-family: "Aeonik";
    src: url("../fonts/Aeonik-Light.woff2") format("woff2"),
        url("../fonts/Aeonik-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Aeonik";
    src: url("../fonts/Aeonik-Bold.woff2") format("woff2"),
        url("../fonts/Aeonik-Bold.woff") format("woff");
    /* font-weight: bold; */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Aeonik";
    src: url("../fonts/Aeonik-Regular.woff2") format("woff2"),
        url("../fonts/Aeonik-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    --blue: #15049e;
    --white: #ffffff;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/fontawesome.min.css");

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
.btn {
    font-family: "Aeonik" !important;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none !important;
}

.btn,
.form-control {
    box-shadow: none !important;
    outline: none !important;
}

.fleet_view_cantaint .container {
    max-width: 1440px !important;
}

.fleet_view_cantaint_login {
    height: 100%;
}

.w-70 {
    width: 70px;
}

.w-60 {
    width: 60px;
}

.w-85 {
    width: 85px;
}

.topbar {
    min-height: 92px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #17159b;
}

.topbar .container {
    max-width: 95% !important;
    margin-left: 30px;
}

.topbar .topbar_logo {
    width: 184px;
    height: 54px;
    margin-left: 22px;
}

.thermostat-details {
    padding: 40px 0;
}

.thermostat-details h3 {
    color: #15049e;
    font-family: Aeonik;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 0;
    padding-bottom: 35px;
}

.thermostat-details .table thead th {
    color: #15049e;
    font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    border: none;
    white-space: nowrap;
    padding: 15px 7px;
}

.thermostat-details .table tbody td {
    color: #15049e;
    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    vertical-align: middle;
    border-top: #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-bottom-style: dashed;
    padding: 6px 7px;
}

.thermostat-details .table tbody td .btn-primary {
    background-color: #65f688 !important;
    border-radius: 40px !important;
    padding: 10px 0 !important;
    height: auto !important;
    border-width: 2px;
    min-width: 140px !important;
    font-weight: 700 !important;
    font-size: 12px;
    color: #15049e !important;
    border-color: #15049e !important;
}

.thermostat-details .table tbody td .btn-secondary {
    background-color: #888 !important;
    border-radius: 40px !important;
    padding: 10px 0 !important;
    height: auto !important;
    border-width: 2px;
    min-width: 140px !important;
    font-weight: 700 !important;
    font-size: 12px;
    color: #fff !important;
}

.login_content {
    padding: 75px 0 100px 0;
}

.login_content .card {
    border: none;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 10px 29px 0px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: 0 auto;
}

.login_content .card h2 {
    color: #15049e;
    font-family: Aeonik;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 40px 0;
}

.login_content .card label {
    color: #15049e;
    font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 10px 0;
}

.login_content .card .forgot_password a {
    color: #15049e;
    font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline !important;
}

.login_content .card .form-control {
    height: 45px;
    color: #15049e;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none !important;
}

.tableUpper {
    display: flex;
    justify-content: space-between;
}

.countss {
    margin-left: 100px !important;
}

.devicetype {
    width: 162px !important;
}

.details {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.details:hover {
    cursor: pointer;
}

.tableTR {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tableborder {
    border-bottom: 1px solid rgba(21, 4, 158, 1);
    margin-bottom: 20px;
    margin-top: 10px;
    height: 20px;
}

.login_content .card .form-control::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #15049e;
    font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login_content .card .form-control::placeholder {
    color: #15049e;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login_content .card form .btn {
    background-color: #15049e;
    padding: 8px 50px;
    margin-top: 20px;
}

.thermostat-details .table tbody td .btn-light {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    border: 2px solid #15049e !important;
    color: #15049e !important;
    background: #fff !important;
    border-radius: 40px !important;
    padding: 10px 0 !important;
    height: auto !important;
    border-width: 2px;
    min-width: 140px !important;
}

.fleet_view {
    padding: 37px 65px;
}

.fleet_view .container {
    max-width: 1440px !important;
    margin: 0 auto;
}

.fleet_view .fleet_view_left {
    padding-right: 50px;
}

.fleet_view .location_filter {
    position: relative;
}

.fleet_view .location_filter .customs_elect {
    width: 120px !important;
    height: 35px;
    position: absolute;
    top: 12px;
    right: 69px;
    z-index: 9;
    border: 1px solid #15049E;
    border-radius: 3px;
    outline: none;
    color: #15049E;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    background-color: #f0eefc;
}

.main_title {
    color: rgba(21, 4, 158, 1);

    font-family: Aeonik;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 0;
    padding-bottom: 10px;
    width: 174px;
    height: 42px;
}

.mapImage {
    width: 638px;
    height: 393px;
    top: 178px;
    left: 41px;
    gap: 0px;
    border: 1px 0px 0px 0px;

    border: 1px solid rgba(21, 4, 158, 1);
}

.fleet_view .btn-light-green {
    background-color: #5ef884 !important;
    border-radius: 50px !important;
    padding: 10px 0 !important;
    height: auto !important;
    border-width: 2px;
    min-width: 140px !important;
    font-weight: 700 !important;
    border: 1px solid #15049e;

    color: #15049e;
    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.fleet_view .device_counts {
    background-color: rgba(240, 238, 252, 1);
    padding: 37px 20px 0 20px;
    margin-top: 35px;
    /* width: 638px; */
    height: 296px;
    top: 606px;
    left: 41px;
    gap: 0px;
}

.fleet_view .device_counts h4 {
    color: #15049e;
    font-family: Aeonik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    padding-bottom: 10px;
}

.fleet_view .dispatchable_forecarst h4 {
    color: rgba(21, 4, 158, 1);
    font-family: Aeonik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    margin: 0;
    padding-top: 10px;
    width: 358px;
    height: 27px;
    top: 544px;
    left: 779px;
    gap: 0px;
}

.capicityforcast {
    height: 340px;
    width: 100%;
    margin-left: -20px;
    margin-top: 30px;
}

/* .fleet_view .device_counts .table td:nth-child(1),
.fleet_view .device_counts .table td:nth-child(2) {
    width: 90%;
} */

.fleet_view .device_counts .table td {
    border: none;
}

.fleet_view .device_counts .table td,
.fleet_view .device_counts .table td a {
    padding: 8px 0;
    color: #15049e;
    font-family: Aeonik;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.fleet_view .device_counts .table td a {
    border-bottom: 1px solid #15049e;
    padding: 0;
}

.dispatch_available_capacity {
    display: grid;
    gap: 20px;
    height: 350px;
}

.fleet_view .dispatch_available_capacity h3 .btn {
    min-width: 198px !important;
    height: 60px !important;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fleet_view .dispatch_available_capacity .card_box {
    background-color: #f0eefc;
    padding: 20px;
    text-align: center;
    margin-bottom: 15px;
    height: 133px;
    display: grid;
    align-items: center;
}

.fleet_view .dispatch_available_capacity .card_box p {
    color: rgba(21, 4, 158, 1);
    font-family: Aeonik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    padding-bottom: 10px;
}

/* 
.card_box p {
    margin-top: 20px !important;
} */

.fleet_view .dispatch_available_capacity .card_box p b {
    width: 191px;
    height: 27px;
    top: 269px;
    left: 846px;
    gap: 0px;
}

.fleet_view .dispatch_available_capacity .card_box p span {
    font-weight: 400;
    font-size: 36px;
    padding-top: 19px;
    display: block;
    width: 165px;
    height: 41px;

    margin-left: 60px;
    gap: 0px;
}

.fleet_view .dispatch_available_capacity h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    padding: 0;
    width: 100%;
    height: 27px;
    margin-top: -23px;
    left: 801px;
    gap: 0px;

    font-family: Aeonik;
    font-size: 24px;
    font-weight: 400;
    line-height: 27.36px;
    text-align: left;
    color: rgba(21, 4, 158, 1);
}

.dispatchLink {
    width: 112px;
    height: 24px;
    top: 166px;
    left: 1237px;
    gap: 0px;
    font-family: Aeonik;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(21, 4, 158, 1);
}

.dispatch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 198px;
    height: 60px;
    top: 148px;
    left: 1194px;
    border: 1px solid rgba(21, 4, 158, 1);
    gap: 0px;
    border-radius: 500px;

    background: rgba(94, 248, 132, 1);
}

.dispatch-resources .main_title {
    border-bottom: 1px solid #d0cdec;
    margin-bottom: 67px;
}

.dispatch-resources ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.dispatch-resources ul li {
    width: 32%;
    margin: 6px;
    background: #f0eefc;
}

.dispatch-resources ul li .card {
    background: #f0eefc;
    border: none;
}

.dispatch-resources ul li .card h4 {
    color: #15049e;
    font-family: Aeonik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 1px solid #d0cdec;
    margin: 0;
    padding: 15px 11px;
}

.dispatch-resources ul li .table td {
    color: #15049e;
    font-family: Aeonik;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    vertical-align: middle;
}

.dispatch-resources ul li .table td .btn-primary {
    background-color: #65f688 !important;
    border-radius: 40px !important;
    padding: 6px 0 !important;
    height: auto !important;
    border-width: 2px;
    min-width: 140px !important;
    font-weight: 700 !important;
    font-size: 16px;
    color: #15049e !important;
    border-color: #15049e !important;
}

.dispatch-resources ul li .table td .qty-input {
    color: #000;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.dispatch-resources ul li .table td .qty-input .product-qty,
.dispatch-resources ul li .table td .qty-input .qty-count {
    background: transparent;
    color: #15049e;
    font-weight: bold;
    font-size: inherit;
    border: none;
    display: inline-block;
    min-width: 0;
    height: 2.5rem;
    line-height: 1;
    font-size: 16px;
}

.dispatch-resources ul li .table td .qty-input .product-qty:focus,
.dispatch-resources ul li .table td .qty-input .qty-count:focus {
    outline: none;
}

.countin {
    margin-left: 31px;
}

.dispatch-resources ul li .table td .qty-input .product-qty {
    width: 50px;
    min-width: 0;
    display: inline-block;
    text-align: center;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.dispatch-resources ul li .table td .qty-input .product-qty::-webkit-outer-spin-button,
.dispatch-resources ul li .table td .qty-input .product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.dispatch-resources ul li .table td .qty-input .qty-count {
    padding: 0;
    cursor: pointer;
    width: 2.5rem;
    font-size: 16px;
    text-indent: -100px;
    overflow: hidden;
    position: relative;
    height: 22px;
}

.dispatch-resources ul li .table td .qty-input .qty-count:before,
.dispatch-resources ul li .table td .qty-input .qty-count:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    display: block;
    background: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.4;
}

.dispatch-resources ul li .table td .qty-input .qty-count--minus {
    border-right: 1px solid #786cd0;
}

.dispatch-resources ul li .table td .qty-input .qty-count--add {
    border-left: 1px solid #786cd0;
}

.dispatch-resources ul li .table td .qty-input .qty-count--add:after {
    transform: rotate(90deg);
}

.dispatch-resources ul li .table td .qty-input .qty-count:disabled {
    color: #ccc;
    cursor: not-allowed;
    border-color: transparent;
}

.dispatch-resources ul li .table td .qty-input .qty-count:disabled:before,
.qty-input .qty-count:disabled:after {
    background: #ccc;
}

.dispatch-resources ul li .table td .qty-input {
    border-radius: 10px;
    border: 0.5px solid #786cd0;
    background: #affcc2;
    width: 107px;
    float: right;
}

@media (max-width: 1440px) {
    .topbar .container {
        max-width: 1440px !important;
    }

    .fleet_view .dispatch_available_capacity .card_box p {
        font-size: 18px;
    }

    .fleet_view .dispatch_available_capacity .card_box p span {
        font-size: 26px;
        padding-top: 10px;
        margin-left: 0px;
    }

    .fleet_view .dispatch_available_capacity h3 .btn {
        min-width: 156px !important;
        height: 46px !important;
        font-size: 18px;
    }

    .fleet_view {
        padding: 37px 16px;
    }

    .dispatch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        margin-top: 6px;
        left: 1194px;
        border: 1px solid rgba(21, 4, 158, 1);
        gap: 0px;
        border-radius: 500px;

        background: rgba(94, 248, 132, 1);
    }

    .customs_elect {
        width: 73px !important;
        height: 35px;
        position: absolute;
        top: 12px;
        right: 69px;
        z-index: 9;
        border: 1px solid #15049e;
        border-radius: 3px;
        outline: none;
        color: #15049e;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        background-color: #f0eefc;
    }

    .dispatch_available_capacity {
        display: grid;
        gap: 20px;
        height: 530px;
    }

    .fleet_view .device_counts {
        background-color: rgba(240, 238, 252, 1);
        padding: 28px 20px 0 20px;
        margin-top: 35px;
        width: 100%;
        height: 330px;
        top: 606px;
        left: 41px;
        gap: 0px;
    }

    .card_box {
        background-color: #f0eefc;
        padding: 20px;
        text-align: justify;
        margin-bottom: 15px;
        height: 180px !important;
        justify-content: center;
    }

    /* .card_box p {
        margin-top: 30px !important;
    } */

    /* 
    .kwh {
        font-weight: 400;
        font-size: 36px;
        padding-top: 19px;
        display: block;
        width: 165px;
        height: 41px;
        margin-left: 20px !important;
        gap: 0px;
    } */

    .capicityforcast {
        height: 286px;
        width: 100%;
        margin-left: -20px;
        margin-top: 30px;
    }

    .countss {
        margin-left: 50px !important;
    }

    .devicetype {
        width: 80px !important;
    }

    .countin {
        margin-left: -130px;
    }
}

@media (max-width: 1200px) {
    .fleet_view .fleet_view-left {
        padding-right: 0;
    }

    .fleet_view .dispatch_available_capacity .card_box p {
        font-size: 16px;
    }

    .fleet_view .dispatch_available_capacity .card_box p span {
        font-size: 22px;
        padding-top: 6px;
    }

    .fleet_view .dispatch_available_capacity h3,
    .fleet_view .device_counts .table td,
    .fleet_view .device_counts .table td a {
        font-size: 20px;
    }

    .fleet_view {
        padding: 37px 55px;
    }

    .dispatch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 166px;
        height: 48px;
        margin-top: 6px;
        left: 1194px;
        border: 1px solid rgba(21, 4, 158, 1);
        gap: 0px;
        border-radius: 500px;

        background: rgba(94, 248, 132, 1);
    }

    .customs_elect {
        width: 73px !important;
        height: 35px;
        position: absolute;
        top: 12px;
        right: 69px;
        z-index: 9;
        border: 1px solid #15049e;
        border-radius: 3px;
        outline: none;
        color: #15049e;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        background-color: #f0eefc;
    }

    .dispatch_available_capacity {
        display: grid;
        gap: 30px;
    }

    .fleet_view .device_counts {
        background-color: rgba(240, 238, 252, 1);
        padding: 28px 20px 0 20px;
        margin-top: 35px;
        width: 100%;
        height: 330px;
        top: 606px;
        left: 41px;
        gap: 0px;
    }

    /* .kwh {
        font-weight: 400;
        font-size: 36px;
        padding-top: 19px;
        display: block;
        width: 154px;
        height: 41px;
        margin-left: 120px !important;
        gap: 0px;
    } */

    .capicityforcast {
        height: 286px;
        width: 100%;
        margin-left: -20px;
        margin-top: 30px;
    }

    .countss {
        margin-left: 50px !important;
    }

    .devicetype {
        width: 80px !important;
    }

    .countin {
        margin-left: 0px;
    }

    .fleet_view .fleet_view_left {
        padding-right: 0px;
    }

    .customs_elect {
        width: 53px !important;
        height: 35px;
        position: absolute;
        top: 12px;
        margin-right: -14px;
        z-index: 9;
        border: 1px solid #15049e;
        border-radius: 3px;
        outline: none;
        color: #15049e;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        background-color: #f0eefc;
    }

    .card_box {
        background-color: #f0eefc;
        padding: 20px;
        text-align: justify;
        margin-bottom: 15px;
        height: 131px !important;
        justify-content: center;
    }

    .dispatch-resources ul li .table td .qty-input {
        border-radius: 10px;
        border: 0.5px solid #786CD0;
        background: #AFFCC2;
        width: 107px;
        float: right;
    }

    .fleet_view .fleet_view_left {
        padding-right: 0px;
    }


}

@media (max-width: 991px) {
    .main_title {
        font-size: 28px;
    }

    .fleet_view .btn-primary {
        margin: 10px 0;
    }

    .fleet_view .device_counts .table td,
    .fleet_view .device_counts .table td a {
        font-size: 18px;
    }

    .fleet_view .dispatch_available_capacity h3 {
        display: block;
    }

    .fleet_view .dispatch_available_capacity h3 .btn {
        margin-top: 10px;
    }

    .fleet_view .device_counts h4,
    .fleet_view .dispatchable_forecarst h4 {
        font-size: 20px;
    }

    .thermostat-details .table thead th {
        font-size: 16px;
    }

    .fleet_view {
        padding: 37px 16px;
    }

    .dispatchable_forecarst {
        margin-top: 80px !important;
    }

    .dispatch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        margin-top: 10px;
        left: 1194px;
        border: 1px solid rgba(21, 4, 158, 1);
        gap: 0px;
        border-radius: 500px;
        background: rgba(94, 248, 132, 1);
    }

    .dispatchLink {
        font-size: 19px;
    }

    .customs_elect {
        width: 73px !important;
        height: 35px;
        position: absolute;
        top: 12px;
        right: 69px;
        z-index: 9;
        border: 1px solid #15049e;
        border-radius: 3px;
        outline: none;
        color: #15049e;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        background-color: #f0eefc;
    }

    .dispatch_available_capacity {
        display: grid;
        gap: 60px;
    }

    .fleet_view .device_counts {
        background-color: rgba(240, 238, 252, 1);
        padding: 28px 20px 0 20px;
        margin-top: 35px;
        width: 100%;
        height: 330px;
        top: 606px;
        left: 41px;
        gap: 0px;
    }

    /* .kwh {
        font-weight: 400;
        font-size: 36px;
        padding-top: 19px;
        display: block;
        width: 154px;
        height: 41px;
        margin-left: 60px !important;
        gap: 0px;
    } */

    .capicityforcast {
        height: 286px;
        width: 100%;
        margin-left: -20px;
        margin-top: 30px;
    }


    .countss {
        margin-left: 50px !important;
    }

    .card_box {
        background-color: #f0eefc;
        padding: 20px;
        text-align: justify;
        margin-bottom: 15px;
        height: 133px !important;
        align-items: center;
        justify-content: center;
        /* width: 200px; */
    }




    .card_box p {
        margin-top: 10px !important;
    }

    .countin {
        margin-left: 0px !important;
    }

    .devicetype {
        width: 80px !important;
    }

    .countin {
        margin-left: 0px;
    }

    .customs_elect {
        width: 53px !important;
        height: 35px;
        position: absolute;
        top: 12px;
        margin-right: -14px;

    }
}

@media (max-width: 767px) {
    .fleet_view .dispatch_available_capacity h3 {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .fleet_view .dispatchable_forecarst {
        margin-top: 20px;
    }

    .fleet_view .dispatch_available_capacity h3 {
        display: flex;
    }

    .fleet_view .device_counts {
        background-color: rgba(240, 238, 252, 1);
        padding: 20px 20px 0 20px;
        margin-top: 35px;
        width: 100%;
        height: 296px;
        top: 606px;
        left: 41px;
        gap: 0px;
    }

    .customs_elect {
        width: 53px !important;
        height: 35px;
        position: absolute;
        top: 12px;
        margin-right: -14px;
        z-index: 9;
        border: 1px solid #15049e;
        border-radius: 3px;
        outline: none;
        color: #15049e;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        background-color: #f0eefc;
    }

    .countin {
        margin-left: -150px;
    }

    .dispatch_available_capacity {
        display: grid;
        gap: 75px;
    }

    /* .kwh {
        font-weight: 400;
        font-size: 36px;
        padding-top: 19px;
        display: block;
        width: 154px;
        height: 41px;
        margin-left: 8px !important;
        gap: 0px;
    } */

    .countin {
        margin-left: 0px !important;
    }

    .dispatch_available_capacity {
        display: grid;
        gap: 0px;
    }

    .kwh {
        font-weight: 400;
        font-size: 36px;
        padding-top: 19px;
        display: block;
        width: 154px;
        height: 41px;
        margin-left: 8px !important;
        gap: 0px;
    }

    .dispatchable_forecarst {
        margin-top: 50px !important;
    }
}

@media (max-width: 500px) {
    .thermostat-details h3 {
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 20px;
    }

    .thermostat-details .table thead th {
        font-size: 16px;
    }

    .thermostat-details .table tbody td {
        font-size: 14px;
    }

    .thermostat-details .table tbody td .btn-dafault,
    .thermostat-details .table tbody td .btn-primary {
        font-size: 14px !important;
    }

    .main_title {
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 20px;
    }

    .fleet_view .btn-primary {
        font-size: 14px !important;
        display: block;
        width: 150px;
        margin: 20px auto 0 auto;
    }

    .fleet_view .dispatch_available_capacity h3 {
        display: block;
        text-align: center;
    }

    .fleet_view .device_counts {
        background-color: rgba(240, 238, 252, 1);
        padding: 20px 20px 0 20px;
        margin-top: 35px;
        width: 100%;
        height: 296px;
        top: 606px;
        left: 41px;
        gap: 0px;
    }

    .countss {
        margin-left: 0px !important;
    }

    .customs_elect {
        width: 53px !important;
        height: 35px;

    }

    .devicetype {
        width: 80px !important;
    }

    .countin {
        margin-left: 0px !important;
    }

    .dispatch_available_capacity {
        display: grid;
        gap: 75px;
    }

    .dispatch {
        width: 100%;
        margin-top: 11px;
        margin-left: 20px;
    }

    .dispatch_available_capacity h3 {
        margin-left: -10px;
    }

    .capicityforcast {
        height: 340px;
        width: 100%;
        margin-left: 0px;
        margin-top: 30px;
    }

    /* 
    .kwh {
        font-weight: 400;
        font-size: 36px;
        padding-top: 19px;
        display: block;
        width: 154px;
        height: 41px;
        margin-left: 70px !important;
        gap: 0px;
    } */

    .dispatchable_forecarst {
        margin-top: 200px !important;
    }
}