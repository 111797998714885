body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --font-aeonik: Aeonik;

    /* font sizes */
    --font-size-5xl: 24px;
    --font-size-lgi: 19px;
    --font-size-base: 16px;
    --font-size-xs: 12px;
    --font-size-17xl: 36px;
    --font-size-10xl: 29px;
    --font-size-3xl: 22px;

    /* Colors */
    --neutral-white: #fff;
    --primary-purple-duke-blue: #15049e;
    --primary-purple-magnolia: #f0eefc;
    --secondary-green-spring-green: #5ef884;
    --primary-purple-medium-slate-blue: #786cd0;
    --color-gray: rgba(0, 0, 0, 0.4);
    --secondary-green-celadon: #affcc2;

    /* Gaps */
    --gap-58xl: 77px;
    --gap-19xl: 38px;
    --gap-lgi: 19px;
    --gap-16xl: 35px;
    --gap-mid: 17px;
    --gap-xl: 20px;
    --gap-43xl: 62px;
    --gap-20xl: 39px;
    --gap-xs: 12px;
    --gap-11xl: 30px;
    --gap-mini: 15px;
    --gap-46xl: 65px;
    --gap-34xl: 53px;
    --gap-7xl: 26px;

    /* Paddings */
    --padding-17xl: 36px;
    --padding-9xl: 28px;
    --padding-3xl: 22px;
    --padding-4xs: 9px;
    --padding-12xs: 1px;
    --padding-lg: 18px;
    --padding-4xl: 23px;
    --padding-8xs: 5px;
    --padding-10xs: 3px;
    --padding-3xs-3: 9.3px;
    --padding-base-1: 16.1px;
    --padding-base-2: 16.2px;
    --padding-20xl: 39px;
    --padding-9xs: 4px;
    --padding-11xl: 30px;

    /* Border radiuses */
    --br-481xl: 500px;
    --br-3xs: 10px;
}